import React from "react"
import moment from "moment"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({data}) => {
    let posts = data.allMarkdownRemark.edges
      .map(edge => edge.node.frontmatter)
      .sort((a, b) => -1 * moment(a.date).diff(moment(b.date)));


    return (
        <Layout>
            <SEO title="posts"/>
            <h3>posts</h3>
            <ul style={{listStyleType: 'none', marginLeft: 0}}>
                {posts.map(post => <li>
                    <span style={{marginRight: '0.5rem'}}>{moment(post.date).format('MM/DD/YYYY')}</span>
                    <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                </li>)}
            </ul>
        </Layout>
    )
};

export default IndexPage

export const query = graphql`
  {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            title
            slug
            date
          }
        }
      }
    }
  }
`
